import styled from "styled-components";
import { Link } from "gatsby";
import { Col } from "react-bootstrap";
import * as SH from "@shared";
import * as theme from "@theme";

export const WrapperBox = styled(SH.Wrapper.Box)`
  background-color: ${theme.colors.whitegray};
  padding: 0;
  margin: 0;
  margin-bottom: 63px;
  height: auto;

  &:nth-of-type(even) {
    padding: 0;
  }
  @media ${theme.breakpoints.desktop} {
    margin-bottom: 85px;
  }
`;
export const ColImg = styled(Col)`
  box-shadow: -2px 8px 22px rgba(65, 64, 66, 0.05);
  background-color: ${theme.colors.white};
`;

export const ColText = styled(Col)`
  padding: 20px;

  @media ${theme.breakpoints.desktop} {
    padding: 30px;
  }
`;

export const Image = styled(SH.Image)`
  overflow: hidden;
  object-fit: fill;
  height: 100%;
  width: 100%;
  box-shadow: -2px 8px 22px rgba(65, 64, 66, 0.05);
`;

export const ReadMore = styled(Link)`
  ${SH.Text.TextButtonCss}
  color: ${theme.colors.lightblue};
  text-align: right;
  display: block;
  text-transform: uppercase;
`;
