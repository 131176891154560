import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import useFetchAssets from "./DownloadableAssets.hooks";
import * as S from "./DownloadableAssets.styles";
import * as SH from "@shared";

export const DownloadableAssets = () => {
  const { data } = useFetchAssets();

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col lg="10">
            <SH.Text.Header2>downloadable assets</SH.Text.Header2>
          </Col>
          {data.allStrapiResourcesPage.edges[0].node.downloadableAssets.map(
            ({ title, id, file }) => (
              <S.VacanciesCol
                key={id}
                href={file.localFile.publicUrl}
                className="col-lg-10"
                download={`${file.name} - ${title} coderspeak`}
              >
                {title}
              </S.VacanciesCol>
            )
          )}
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};
