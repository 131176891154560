import { useStaticQuery, graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";

const useFetchArticle = () => {
  const data: TData = useStaticQuery(graphql`
    {
      allStrapiBlog(sort: { order: DESC, fields: base___publishDate }) {
        nodes {
          id
          title
          slug
          base {
            publishDate(formatString: "MMMM YYYY")
            introduction
            category {
              nameCategory
            }
            image {
              localFile {
                publicUrl
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    quality: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              alternativeText
            }
          }
        }
      }
    }
  `);

  return { data };
};

export default useFetchArticle;

export type TData = {
  allStrapiBlog: {
    nodes: {
      id: number;
      title: string;
      slug: string;
      base: {
        publishDate: string;
        introduction: string;
        category: {
          nameCategory: string;
        };
        image: {
          localFile: {
            publicUrl: string;
            childImageSharp: {
              gatsbyImageData: IGatsbyImageData;
            } | null;
          };
          alternativeText: string;
        };
      };
    }[];
  };
};
