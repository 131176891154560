import styled from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";
import ArrowDownload from "./arrow-download.svg";

export const VacanciesCol = styled.a`
  ${SH.Text.Header3Css};
  background: ${theme.colors.whitegray};
  height: 48px;
  width: 100%;
  display: block;
  width: 100%;
  padding: 0 15px;
  color: inherit;
  font-weight: ${theme.fonts.fontWeight.medium500};
  line-height: 4.8rem !important;
  position: relative;
  margin-bottom: 23px;
  &:first-of-type {
    margin-top: 43px;
  }
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 48px;
    right: 20px;
    background: url(${ArrowDownload}) no-repeat center center;
  }

  @media ${theme.breakpoints.desktop} {
    padding: 0 24px;
  }
`;
