import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import useFetchArticle from "./NewsBlog.hooks";
import * as S from "./NewsBlog.styles";
import * as SH from "@shared";
import ReactHtmlParser from "react-html-parser";

export const NewsBlog = () => {
  const { data } = useFetchArticle();

  return (
    <SH.Wrapper.Primary paddingTop={paddingTop}>
      <Container>
        <Row className="justify-content-center">
          <Col lg="10">
            <SH.Text.Header2>news</SH.Text.Header2>
          </Col>
          <Col xs="12" xl="10" className="pt-5">
            {!!data.allStrapiBlog.nodes &&
              data.allStrapiBlog.nodes.map(({ base, title, slug, id }) => (
                <S.WrapperBox key={id}>
                  <Row className="h-100 justify-content-between mx-0">
                    <S.ColImg className="px-0" lg="7">
                      <SH.Img.Plugin
                        src={base.image.localFile.publicUrl}
                        gatsbyImageSrc={
                          base.image.localFile.childImageSharp?.gatsbyImageData
                        }
                        alt={base.image?.alternativeText}
                        objectFit="fill"
                        component={S.Image}
                      />
                    </S.ColImg>
                    <S.ColText lg="5">
                      <SH.Text.SubTitle1>
                        {base.category.nameCategory}
                      </SH.Text.SubTitle1>
                      <SH.Text.SubTitle3 colorComponent="lightblue">
                        {base.publishDate}
                      </SH.Text.SubTitle3>
                      <SH.Text.Header3>{title}</SH.Text.Header3>
                      <SH.Text.MarkdownTextBody>
                        {ReactHtmlParser(base.introduction)}
                      </SH.Text.MarkdownTextBody>
                      <S.ReadMore to={`/news/${slug}`}>read more</S.ReadMore>
                    </S.ColText>
                  </Row>
                </S.WrapperBox>
              ))}
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const paddingTop = {
  mobile: 100,
  desktop: 90,
};
