import { useStaticQuery, graphql } from "gatsby";

const useFetchAssets = () => {
  const data: TData = useStaticQuery(graphql`
    {
      allStrapiResourcesPage {
        edges {
          node {
            downloadableAssets {
              title
              id
              file {
                localFile {
                  publicUrl
                }
                name
              }
            }
          }
        }
      }
    }
  `);

  return { data };
};

export default useFetchAssets;

export type TData = {
  allStrapiResourcesPage: {
    edges: {
      node: {
        downloadableAssets: {
          title: string;
          id: number;
          file: {
            localFile: {
              publicUrl: string;
            };
            name: string;
          };
        }[];
      };
    }[];
  };
};
